import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Billing from './Billing';
import { updateUserSettings } from '../api/wordpress';
import { useAuth } from '../context/AuthContext';
import { ArrowRightOnRectangleIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';

function Settings() {
  const { user, logout, isLoading, checkLoginStatus, userUsage, userPlan, fetchUserUsageAndPlan } = useAuth();
  const [localUser, setLocalUser] = useState({
    name: '',
  });
  const [password, setPassword] = useState({
    current: '',
    new: '',
    confirm: '',
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [isUsageStatsModalOpen, setIsUsageStatsModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      setLocalUser({
        name: user.name || '',
      });
      fetchUserUsageAndPlan();
    }
  }, [user, fetchUserUsageAndPlan]);

  useEffect(() => {
    if (location.state && location.state.usageLimitExceeded) {
      setError('You have exceeded your usage limit. Please upgrade your plan to continue creating designs.');
    }
  }, [location]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalUser({ ...localUser, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUserSettings(localUser);
      setSuccessMessage('User information updated successfully');
      await checkLoginStatus(); // Refresh user data
    } catch (err) {
      console.error('Error updating user information:', err);
      setError('Failed to update user information');
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (password.new !== password.confirm) {
      setError('New passwords do not match');
      return;
    }
    try {
      await updateUserSettings({ password: password.new });
      setSuccessMessage('Password updated successfully');
      setPassword({ current: '', new: '', confirm: '' });
    } catch (err) {
      setError('Failed to update password');
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleUpgrade = () => {
    setIsBillingModalOpen(true);
  };

  const openUsageStatsModal = () => {
    setIsUsageStatsModalOpen(true);
  };

  const closeUsageStatsModal = () => {
    setIsUsageStatsModalOpen(false);
  };

  const getMonthName = (monthNumber) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[parseInt(monthNumber) - 1] || '';
  };

  const getDaysUntilEndOfMonth = () => {
    const now = new Date();
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const daysRemaining = lastDayOfMonth.getDate() - now.getDate() + 1;
    return daysRemaining;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100 dark:bg-gray-900">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-900">
          <div className="container mx-auto px-4 sm:px-6 py-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
              <h3 className="text-gray-700 dark:text-gray-200 text-2xl font-medium mb-2 sm:mb-0">Settings</h3>
            </div>
            {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">{error}</div>}
            {successMessage && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">{successMessage}</div>}
            <div className="flex flex-col lg:flex-row lg:space-x-6 space-y-6 lg:space-y-0 h-auto sm:h-auto">
              <div className="w-full lg:w-2/3 bg-white dark:bg-gray-700 rounded-lg shadow-md p-4">
                <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-600">
                  <form onSubmit={handleSubmit}>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Personal Information</h3>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">View your username below.</p>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                      <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Username</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={localUser.name}
                          readOnly
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100 dark:bg-gray-600 dark:text-gray-200 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </form>
                  <form onSubmit={handlePasswordSubmit} className="pt-8">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Change Password</h3>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">Update your password associated with your account.</p>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                      <div className="sm:col-span-2">
                        <label htmlFor="current_password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Current password</label>
                        <input
                          type="password"
                          name="current"
                          id="current_password"
                          value={password.current}
                          onChange={handlePasswordChange}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-600 dark:text-gray-200 dark:border-gray-500"
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label htmlFor="new_password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">New password</label>
                        <input
                          type="password"
                          name="new"
                          id="new_password"
                          value={password.new}
                          onChange={handlePasswordChange}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-600 dark:text-gray-200 dark:border-gray-500"
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label htmlFor="confirm_password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Confirm password</label>
                        <input
                          type="password"
                          name="confirm"
                          id="confirm_password"
                          value={password.confirm}
                          onChange={handlePasswordChange}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-600 dark:text-gray-200 dark:border-gray-500"
                        />
                      </div>
                    </div>
                    <div className="mt-6 flex justify-end">
                      <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 flex items-center">Update Password</button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="w-full lg:w-1/3 space-y-6">
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow-md p-4">
                <div className="flex items-center mb-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Usage Stats</h3>
                  <QuestionMarkCircleIcon 
                    className="h-5 w-5 ml-2 text-gray-400 cursor-pointer hover:text-gray-500" 
                    onClick={openUsageStatsModal}
                  />
                </div>
                {userUsage && userPlan ? (
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">Your Plan: {userPlan.name}</p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">Monthly Credit Allowance: {userPlan.tokens_per_month}</p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                    Credits Used This Month: {userUsage.total_images || 0}
                    </p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                    Monthly Usage Limit: {userPlan.tokens_per_month}
                    </p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                    Current Month: {getMonthName(userUsage.current_month)} {userUsage.current_year}
                    </p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                    Credits reset in: {getDaysUntilEndOfMonth()} days
                    </p>
                    <button
                      onClick={handleUpgrade}
                      className="mt-4 w-full px-4 py-2 bg-[#CF08FF] hover:bg-[#B007E6] text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#CF08FF]"
                    >
                      Upgrade Plan
                    </button>
                  </div>
                ) : (
                  <p className="text-sm text-gray-600 dark:text-gray-400">Loading usage stats...</p>
                )}
              </div>
                <div className="bg-white dark:bg-gray-700 rounded-lg shadow-md p-4">
                  <button
                    onClick={handleLogout}
                    className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-gray-600 dark:text-gray-200 dark:border-gray-500 dark:hover:bg-gray-700 flex items-center justify-center"
                  >
                    <ArrowRightOnRectangleIcon className="h-5 w-5 mr-2" />
                    Log out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Billing isOpen={isBillingModalOpen} onClose={() => setIsBillingModalOpen(false)} />]
        {/* Usage Stats Modal */}
      {isUsageStatsModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Credit Usage Information</h3>
              <button onClick={closeUsageStatsModal} className="text-gray-400 hover:text-gray-500">
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="space-y-4 text-sm text-gray-600 dark:text-gray-400">
              <p>🖼️ Generate 1 image: 1 credit</p>
              <p>🎭 Remove a background: 4 credits (coming soon)</p>
              <p>🔍 Upscale an image: 2 credits (coming soon)</p>
              <p>😊 Fix a face: 2 credits (coming soon)</p>
              <p>✏️ Remove text: 2 credits (coming soon)</p>
              <p>🧠 Train a character: 20 credits (coming soon)</p>
              <div className="border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
                <p className="font-medium mb-2">About your credit allowance:</p>
                <p>During the Dezygn V2 Beta, all plans will be capped at 500 images a month to ensure server stability. After the beta, your credits will be adjusted to match your current Dezygn V1 plan.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Settings;